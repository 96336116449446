.navbar {
  height: 60px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  background:#fff;
  color: #555;
  position: sticky;
  border-left:none;
  z-index:99;
  top: 0;
  width:100%;
  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    gap:1rem;
    // padding-left:0;
    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid #d9e2ec;
      padding: 3px;
      width:200px;
      border-radius: 5px;
      input {
        border: none;
        outline: none;
        background: transparent;
        width:200px;
        &::placeholder {
          font-size: 12px;
        }
      }
    } 
.heading{
  color:#228f47;
  font-weight: bold;
  font-size:16px;
  background:#befad3;
  padding:0.25rem .75rem;
  border-radius:5px;
}
.blue{
  background:#CDF5FD;
  padding:0.25rem .75rem;
  border-radius:5px;
  font-weight: bold;
  font-size:14px;
  a{
    color:#3081D0;
  }
  .icon{
    color:#3081D0;
  }
}
.blue:hover{
  .icon,a{
    color:#1a538d;
    transition: all 0.2s;
  }
}
.red{
  background:#FAD4D4;
  padding:0.25rem .75rem;
  border-radius:5px;
  font-weight: bold;
  font-size:14px;
  .icon,a{
    color:#d34539;
  }
}
.red:hover{
  .icon,a{
    color:#C51605;
    transition: all 0.2s;
  }
}
.green{
  background:#aef37d;
  padding:0.25rem .75rem;
  border-radius:5px;
  font-weight: bold;
  font-size:14px;
  .icon,a{
    color:#49a308;
  }
}
.green:hover{
  .icon,a{
    color:#2d6901;
    transition: all 0.2s;
  }
}
    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;
        margin-left: auto;
        justify-content:center;
        .icon {
          font-size: 20px;
        }
        a{
          display: flex;
          justify-content:center;
          align-items: center;
          gap:0.25rem;
          text-decoration: none;
        }
        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .counter {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }
  .wrapper > :last-child {
    margin-left: auto;
}
.search{
  border-radius: 10px;
 }  
}
