

.MainContainer
{
    background:#F5F7F8;

}
.main-container{
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction:column;
  justify-content: space-around;
}
h3{
  border-bottom:3px solid #228f47;
  text-transform:capitalize;
  display:inline-block;
  margin:2rem 0rem 2rem .5rem;
 }
 h4{
  margin: 1rem 0rem 0rem 1rem;
  border-bottom:1px solid #228f47;
  display:inline-block;
 }
 .paper1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  padding-bottom:0;
  
}
.container-input{
  display:flex;
  width:100%;
  margin-top:1.5rem;
  justify-content: flex-start;
  gap:2.5rem;
}
.first-box{
  border-bottom: 1px solid #F5F7F8;
  background: #fff;
  border-radius: 5px;
}
.delete-btn{
  font-weight:500;
  background:#D71313;
  cursor: pointer;
  color: #fff;
  border: transparent;
  border-radius: 5px;
  letter-spacing:1px;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition:  0.3s ease-in-out all;
  text-transform: capitalize;
  display:flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.delete-btn:hover {
  box-shadow:  0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.select-box{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap:0.75rem;
}
.upload-btn{
  margin-top:1rem;
  font-weight:500;
  background:#228f47;
  cursor: pointer;
  color: #fff;
  border: transparent;
  border-radius: 5px;
  letter-spacing:1px;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition:  0.3s ease-in-out all;
  text-transform: capitalize;
  display:flex;
  justify-content: center;
  align-items: center;
  height:50px;
  font-size: 16px;
  text-transform: uppercase;
}
.upload-btn:hover{
  box-shadow:  0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.upload-btns{
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  gap:3rem;
  align-items: center;
}
.select-warning{
  min-height: 100px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.select-warning-p{
 display: inline-block;
 margin-top: auto;
 
}
.existing-pot{
  display:flex;
  flex-direction: column;
  
  gap:2rem;
  margin-top: 2rem;
  min-height: 200px;
}
.existing-pot-single{
  display:flex;
  flex-direction: row;
  gap:3.25rem;
  align-items: center;
}
.second-box{
  background: #fff;
  margin-bottom:2rem;
  border-radius: 5px;
}
.btn-add{
  width:100%;
}
.paper1 > *:nth-child(1){
  width: 40%;
}
.paper1 > *:nth-child(2) {
  width: 50%;
}

.paper1 > *:nth-child(3) {
  width: 10%;
}
.pot-container{
  display: flex;
  flex-direction:column;
  overflow: hidden;
}
.paper3
{
  padding: 1rem;
}
.green-border {
    border: 2px solid green; /* Change 'green' to your desired border color */
  }

  /* Card Styles */
.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Front Image Styles */
  .front-image {
    max-width: 100%;
  }
  
  /* Side Images Styles */
  .side-views {
    display: flex;
    justify-content: space-between;
  }
  
  .side-image {
    max-width: 30%; /* Adjust the width of side images as needed */
  }
  .swiper-container {
    width: 100%;
    height: 300px; /* Adjust the height as needed */
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
  /* Add this CSS in your stylesheet or component */
.image-slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  
  .carousel-image {
    max-width: 100%;
    max-height: 300px; /* Set the maximum height of the image */
    transition: transform 0.5s ease; /* Add a smooth transition effect */
  }
  
  .prev-button,
  .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }
  
  
.form
{
  display: flex;
  align-Items: center;
}
span
{
  margin-right: 10px;
  font-family: Arial, sans-serif; /* Specify the font */
}
/* Style for the image container */
.image-container {
  position: relative;
  width: 140px;
  height: 140px;
}

/* Style for the image */
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Style for the text overlay */
.text-over-image {
  position: absolute;
  bottom: 0; /* Adjust this to change the vertical position of the text */
  left: 0; /* Adjust this to change the horizontal position of the text */
  width: 100%;
  background-color: rgba(166, 236, 134, 0.7); /* Background color for the text box */
  color: rgb(20, 20, 20); /* Text color */
  padding: 5px; /* Spacing around the text */
  text-align: center; /* Center the text horizontally */
  font-size: 14px; /* Font size of the text */
}
