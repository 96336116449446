.notification-container {
    position: relative;
}

.notification-overlay {
    position: fixed;
    top: 140;
    right: 0;
    width: 30%;
    /* Adjust width as needed */
    max-height: 300px;
    /* Adjust height as needed */
    background-color: rgba(230, 246, 233, 0.9);
    /* Transparent background color */
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    overflow-y: auto;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-right: 10px;
}

.close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.close-icon {
    font-size: 1.5rem;
    color: white;
}

.notification-card-container {
    width: 60%;
    padding-top: 40px;
    /* Add space for close button */
}

.notification-card {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.notification-text {
    margin: 0;
    font-size: 14px;
}

.mark-as-read-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.notification-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.notification-icon {
    font-size: 1.5rem;
}

.notification-count {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    padding: 3px 6px;
    font-size: 12px;
}
.notification-badge {
  background-color: red;
  color: white;
  padding: 5px 8px;
  border-radius: 50%; /* To make it round */
}
