.main {
    display: flex;
    flex-direction: row;
    
   
}

.main div {
   
    margin: 10px 20px;
    width: 100px;
}

.formFirst{
    padding: 10px;
}

.formSecond
{
    padding-top: 20px;
}
.formThird
{
    padding-top: 40px;
    padding-left: 20px;
}
.formFour
{
    padding-top: 30px;
}

.form
{
  display: flex;
  align-Items: center;
}
span
{
  margin-right: 10px;
  font-family: Arial, sans-serif; /* Specify the font */
}


.textareap {
    width: 450px; /* Default width */
    height: 200px; /* Default height */
    resize: both;
    text-align: justify; 
    border: 1px solid #66BF84; /* Add a border for styling */
    padding: 8px; /* Add padding for spacing */
    outline: none; /* Remove the default focus outline */
    font-family: Arial, sans-serif; /* Specify the font */
  }
  